@import url("https://fonts.googleapis.com/css?family=Roboto:400,700");
.userGuide--modal {
  font-family: 'Roboto', sans-serif; }
  .userGuide--modal > div {
    border-radius: 5px; }
  .userGuide--modal h1 {
    font-weight: normal; }
  .userGuide--modal button {
    background: #fff;
    border-radius: 4px;
    border: 1px solid #eee;
    color: #fff;
    cursor: pointer;
    line-height: 30px;
    min-width: 80px;
    padding: 0 10px;
    background-color: #5a6268;
    margin-right: 10px; }
    .userGuide--modal button + button {
      background-color: #17a2b8; }

.userGuide--message {
  border-radius: 5px;
  font-family: 'Roboto', sans-serif; }
  .userGuide--message button {
    background: #fff;
    border-radius: 4px;
    border: 1px solid #eee;
    color: #fff;
    cursor: pointer;
    line-height: 30px;
    min-width: 80px;
    padding: 0 10px;
    margin-right: 10px; }
    .userGuide--message button.next, .userGuide--message button.finish {
      background-color: #17a2b8; }
    .userGuide--message button.skip {
      background-color: #5a6268; }
