@import url(https://fonts.googleapis.com/css?family=Roboto:400,700);
.marker-cluster-small{background-color:rgba(181,226,140,0.6)}.marker-cluster-small div{background-color:rgba(110,204,57,0.6)}.marker-cluster-medium{background-color:rgba(241,211,87,0.6)}.marker-cluster-medium div{background-color:rgba(240,194,12,0.6)}.marker-cluster-large{background-color:rgba(253,156,115,0.6)}.marker-cluster-large div{background-color:rgba(241,128,23,0.6)}.leaflet-oldie .marker-cluster-small{background-color:#b5e28c}.leaflet-oldie .marker-cluster-small div{background-color:#6ecc39}.leaflet-oldie .marker-cluster-medium{background-color:#f1d357}.leaflet-oldie .marker-cluster-medium div{background-color:#f0c20c}.leaflet-oldie .marker-cluster-large{background-color:#fd9c73}.leaflet-oldie .marker-cluster-large div{background-color:#f18017}.marker-cluster{background-clip:padding-box;border-radius:20px}.marker-cluster div{width:30px;height:30px;margin-left:5px;margin-top:5px;text-align:center;border-radius:15px;font:12px "Helvetica Neue", Arial, Helvetica, sans-serif}.marker-cluster span{line-height:30px}.leaflet-cluster-anim .leaflet-marker-icon,.leaflet-cluster-anim .leaflet-marker-shadow{transition:opacity 0.3s ease-in, -webkit-transform 0.3s ease-out;transition:transform 0.3s ease-out, opacity 0.3s ease-in;transition:transform 0.3s ease-out, opacity 0.3s ease-in, -webkit-transform 0.3s ease-out}.leaflet-cluster-spider-leg{transition:stroke-dashoffset 0.3s ease-out, stroke-opacity 0.3s ease-in}

.userGuide--modal {
  font-family: 'Roboto', sans-serif; }
  .userGuide--modal > div {
    border-radius: 5px; }
  .userGuide--modal h1 {
    font-weight: normal; }
  .userGuide--modal button {
    background: #fff;
    border-radius: 4px;
    border: 1px solid #eee;
    color: #fff;
    cursor: pointer;
    line-height: 30px;
    min-width: 80px;
    padding: 0 10px;
    background-color: #5a6268;
    margin-right: 10px; }
    .userGuide--modal button + button {
      background-color: #17a2b8; }

.userGuide--message {
  border-radius: 5px;
  font-family: 'Roboto', sans-serif; }
  .userGuide--message button {
    background: #fff;
    border-radius: 4px;
    border: 1px solid #eee;
    color: #fff;
    cursor: pointer;
    line-height: 30px;
    min-width: 80px;
    padding: 0 10px;
    margin-right: 10px; }
    .userGuide--message button.next, .userGuide--message button.finish {
      background-color: #17a2b8; }
    .userGuide--message button.skip {
      background-color: #5a6268; }

/*
 Buttons style by http://nicolasgallagher.com/lab/css3-github-buttons/
 Changed by Afshin Mehrabani
*/
/* overrides extra padding on button elements in Firefox */
@-webkit-keyframes introjspulse {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0; }
  25% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0.1; }
  50% {
    -webkit-transform: scale(0.1);
            transform: scale(0.1);
    opacity: 0.3; }
  75% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    opacity: 0.5; }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0; } }
@keyframes introjspulse {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0; }
  25% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0.1; }
  50% {
    -webkit-transform: scale(0.1);
            transform: scale(0.1);
    opacity: 0.3; }
  75% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    opacity: 0.5; }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0; } }

.introjs-overlay {
  position: absolute;
  box-sizing: content-box;
  z-index: 999999;
  opacity: 0;
  transition: all 0.3s ease-out; }

.introjs-showElement {
  z-index: 9999999 !important; }

tr.introjs-showElement > td {
  z-index: 9999999 !important;
  position: relative; }

tr.introjs-showElement > th {
  z-index: 9999999 !important;
  position: relative; }

.introjs-disableInteraction {
  z-index: 99999999 !important;
  position: absolute;
  background-color: #ffffff;
  opacity: 0;
  filter: alpha(opacity=0); }

.introjs-relativePosition {
  position: relative; }

.introjs-helperLayer {
  box-sizing: content-box;
  position: absolute;
  z-index: 9999998;
  border-radius: 4px;
  transition: all 0.3s ease-out; }
  .introjs-helperLayer * {
    box-sizing: content-box; }
    .introjs-helperLayer *:before {
      box-sizing: content-box; }
    .introjs-helperLayer *:after {
      box-sizing: content-box; }

.introjs-tooltipReferenceLayer {
  font-family: "Helvetica Neue", Inter, ui-sans-serif, "Apple Color Emoji", Helvetica, Arial, sans-serif;
  box-sizing: content-box;
  position: absolute;
  visibility: hidden;
  z-index: 100000000;
  background-color: transparent;
  transition: all 0.3s ease-out; }
  .introjs-tooltipReferenceLayer * {
    font-family: "Helvetica Neue", Inter, ui-sans-serif, "Apple Color Emoji", Helvetica, Arial, sans-serif; }

.introjs-helperNumberLayer {
  font-family: "Helvetica Neue", Inter, ui-sans-serif, "Apple Color Emoji", Helvetica, Arial, sans-serif;
  color: #9e9e9e;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px; }

.introjs-arrow {
  border: 5px solid transparent;
  content: "";
  position: absolute; }

.introjs-arrow.top {
  top: -10px;
  left: 10px;
  border-bottom-color: #ffffff; }

.introjs-arrow.top-right {
  top: -10px;
  right: 10px;
  border-bottom-color: #ffffff; }

.introjs-arrow.top-middle {
  top: -10px;
  left: 50%;
  margin-left: -5px;
  border-bottom-color: #ffffff; }

.introjs-arrow.right {
  right: -10px;
  top: 10px;
  border-left-color: #ffffff; }

.introjs-arrow.right-bottom {
  bottom: 10px;
  right: -10px;
  border-left-color: #ffffff; }

.introjs-arrow.bottom {
  bottom: -10px;
  left: 10px;
  border-top-color: #ffffff; }

.introjs-arrow.bottom-right {
  bottom: -10px;
  right: 10px;
  border-top-color: #ffffff; }

.introjs-arrow.bottom-middle {
  bottom: -10px;
  left: 50%;
  margin-left: -5px;
  border-top-color: #ffffff; }

.introjs-arrow.left {
  left: -10px;
  top: 10px;
  border-right-color: #ffffff; }

.introjs-arrow.left-bottom {
  left: -10px;
  bottom: 10px;
  border-right-color: #ffffff; }

.introjs-tooltip {
  box-sizing: content-box;
  position: absolute;
  visibility: visible;
  background-color: #ffffff;
  min-width: 250px;
  max-width: 300px;
  border-radius: 5px;
  box-shadow: 0 3px 30px rgba(33, 33, 33, 0.3);
  transition: opacity 0.1s ease-out; }

.introjs-tooltiptext {
  padding: 20px; }

.introjs-tooltip-title {
  font-size: 18px;
  margin: 0;
  padding: 0;
  font-weight: 700;
  float: left;
  line-height: 32px; }

.introjs-tooltip-header {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px; }
  .introjs-tooltip-header:after {
    content: ".";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both; }

.introjs-tooltipbuttons {
  border-top: 1px solid #e0e0e0;
  padding: 10px;
  text-align: right;
  white-space: nowrap; }
  .introjs-tooltipbuttons:after {
    content: "";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both; }

.introjs-button {
  box-sizing: content-box;
  position: relative;
  overflow: visible;
  display: inline-block;
  padding: 0.5rem 1rem;
  border: 1px solid #bdbdbd;
  text-decoration: none;
  text-shadow: 1px 1px 0 #ffffff;
  font-size: 14px;
  color: #424242;
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  background-color: #f4f4f4;
  border-radius: 0.2em;
  zoom: 1;
  *display: inline; }
  .introjs-button:hover {
    outline: none;
    text-decoration: none;
    border-color: #9e9e9e;
    background-color: #e0e0e0;
    color: #212121; }
  .introjs-button:focus {
    outline: none;
    text-decoration: none;
    background-color: #eeeeee;
    box-shadow: 0 0 0 0.2rem rgba(158, 158, 158, 0.5);
    border: 1px solid #616161;
    color: #212121; }
  .introjs-button:active {
    outline: none;
    text-decoration: none;
    background-color: #e0e0e0;
    border-color: #9e9e9e;
    color: #212121; }
  .introjs-button::-moz-focus-inner {
    padding: 0;
    border: 0; }

.introjs-skipbutton {
  box-sizing: content-box;
  color: #616161;
  float: right;
  font-size: 20px;
  cursor: pointer;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  padding: 7px 10px; }
  .introjs-skipbutton:hover, .introjs-skipbutton:focus {
    color: #212121;
    outline: none;
    text-decoration: none; }

.introjs-prevbutton {
  float: left; }

.introjs-nextbutton {
  float: right; }

.introjs-disabled {
  color: #9e9e9e;
  border-color: #bdbdbd;
  box-shadow: none;
  cursor: default;
  background-color: #f4f4f4;
  background-image: none;
  text-decoration: none; }
  .introjs-disabled:hover, .introjs-disabled:focus {
    color: #9e9e9e;
    border-color: #bdbdbd;
    box-shadow: none;
    cursor: default;
    background-color: #f4f4f4;
    background-image: none;
    text-decoration: none; }

.introjs-hidden {
  display: none; }

.introjs-bullets {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px; }
  .introjs-bullets ul {
    box-sizing: content-box;
    clear: both;
    margin: 0 auto 0;
    padding: 0;
    display: inline-block; }
    .introjs-bullets ul li {
      box-sizing: content-box;
      list-style: none;
      float: left;
      margin: 0 2px; }
      .introjs-bullets ul li a {
        transition: width 0.1s ease-in;
        box-sizing: content-box;
        display: block;
        width: 6px;
        height: 6px;
        background: #ccc;
        border-radius: 10px;
        text-decoration: none;
        cursor: pointer; }
        .introjs-bullets ul li a:hover, .introjs-bullets ul li a:focus {
          width: 15px;
          background: #999;
          text-decoration: none;
          outline: none; }
      .introjs-bullets ul li a.active {
        width: 15px;
        background: #999; }

.introjs-progress {
  box-sizing: content-box;
  overflow: hidden;
  height: 10px;
  margin: 10px;
  border-radius: 4px;
  background-color: #e0e0e0; }

.introjs-progressbar {
  box-sizing: content-box;
  float: left;
  width: 0%;
  height: 100%;
  font-size: 10px;
  line-height: 10px;
  text-align: center;
  background-color: #08c; }

.introjsFloatingElement {
  position: absolute;
  height: 0;
  width: 0;
  left: 50%;
  top: 50%; }

.introjs-fixedTooltip {
  position: fixed; }

.introjs-hint {
  box-sizing: content-box;
  position: absolute;
  background: transparent;
  width: 20px;
  height: 15px;
  cursor: pointer; }
  .introjs-hint:focus {
    border: 0;
    outline: 0; }
  .introjs-hint:hover > .introjs-hint-pulse {
    border: 5px solid rgba(60, 60, 60, 0.57); }

.introjs-hidehint {
  display: none; }

.introjs-fixedhint {
  position: fixed; }

.introjs-hint-pulse {
  box-sizing: content-box;
  width: 10px;
  height: 10px;
  border: 5px solid rgba(60, 60, 60, 0.27);
  border-radius: 30px;
  background-color: rgba(136, 136, 136, 0.24);
  z-index: 10;
  position: absolute;
  transition: all 0.2s ease-out; }

.introjs-hint-no-anim .introjs-hint-dot {
  -webkit-animation: none;
          animation: none; }

.introjs-hint-dot {
  box-sizing: content-box;
  border: 10px solid rgba(146, 146, 146, 0.36);
  background: transparent;
  border-radius: 60px;
  height: 50px;
  width: 50px;
  -webkit-animation: introjspulse 3s ease-out;
          animation: introjspulse 3s ease-out;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  position: absolute;
  top: -25px;
  left: -25px;
  z-index: 1;
  opacity: 0; }


.carousel-image {
  vertical-align: middle;
  width: 100%;
  height: 100%;
}
.carousel-container {
  position: relative;
  margin: auto;
}

.carousel-item {
  width: 100%;
  height: 100%;
}

.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}
.prev {
  left: 0;
  border-radius: 3px 0 0 3px;
}
.prev:hover, .next:hover {
  background-color: rgba(0,0,0,0.8);
}

/* Caption text */
.carousel-caption-bottom {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 0;
  width: 100%;
  position: absolute;
  bottom: 30px;
  width: 100%;
  text-align: center;
  text-shadow: 0px 0px 10px rgba(0,0,0,0.8);
}
.carousel-caption-center {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 0;
  width: 100%;
  position: absolute;
  bottom: 50%;
  top: 45%;
  width: 100%;
  text-align: center;
  text-shadow: 0px 0px 10px rgba(0,0,0,0.8);
}

.carousel-caption-top {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 0;
  width: 100%;
  position: absolute;
  top: 8px;
  width: 100%;
  text-align: center;
  text-shadow: 0px 0px 10px rgba(0,0,0,0.8);
}
.dots {
  margin-top: 10px;
  text-align: center;
  position: absolute;
  bottom: 10px;
  width: 100%;
}
.slide-number {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

.dot {
  cursor: pointer;
  height: 8px;
  width: 8px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active, .dot:hover {
  background-color: #717171;
}

.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}
.pause-icon {
  position: absolute;
  /* top: 38%; */
  left: 15px;
  bottom: 2px;
  /* right: 50%; */
  opacity: 1;
}

.pause {
  -webkit-animation-name: pause;
  -webkit-animation-duration: 0.5s;
  animation-name: pause;
  animation-duration: 0.5s;
}

.bar {
  width: 100%;
  background-color: #ddd;
  position: absolute;
    bottom: 0px;
    border-radius: 0 0 10px;
}

.progress {
  width: 1%;
  height: 5px;
  background-color: #042baa;
}

.thumbnails {
  display: flex;
  margin-top: 10px;
  align-items: center;
  overflow: scroll;
}
.thumbnails::-webkit-scrollbar {
  display: none;
}
.thumbnail {
  margin: 0 5px;
}
.active-thumbnail {
  border: #476ff1 solid 3px;
}
@keyframes pause {
  0% {opacity: .2; }
  90% {opacity: 1; }
  
}
@-webkit-keyframes pause {
  0% {opacity: .2; }
  90% {opacity: 1;}
  
}
@-webkit-keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}

@keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}

@media only screen and (max-width: 500px) {
  .prev,
  .next,
  .carousel-caption-bottom, 
  .carousel-caption-center, 
  .carousel-caption-top, 
  .slide-number 
  {
    font-size: 20px !important;
  }
  .dot {
    height: 4px;
    width: 4px;
  }
  .carousel-container{
    max-height: 250px !important;
  }
  .thumbnail {
    max-width: 70px;
  }
  .carousel-caption-bottom{
    bottom: 25px;
  }
}
/*
 * react-circular-progressbar styles
 * All of the styles in this file are configurable!
 */

.CircularProgressbar {
  /*
   * This fixes an issue where the CircularProgressbar svg has
   * 0 width inside a "display: flex" container, and thus not visible.
   */
  width: 100%;
  /*
   * This fixes a centering issue with CircularProgressbarWithChildren:
   * https://github.com/kevinsqi/react-circular-progressbar/issues/94
   */
  vertical-align: middle;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #3e98c7;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.5s ease 0s;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: #d6d6d6;
  /* Used when trail is not full diameter, i.e. when props.circleRatio is set */
  stroke-linecap: round;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: #3e98c7;
  font-size: 20px;
  dominant-baseline: middle;
  text-anchor: middle;
}

.CircularProgressbar .CircularProgressbar-background {
  fill: #d6d6d6;
}

/*
 * Sample background styles. Use these with e.g.:
 *
 *   <CircularProgressbar
 *     className="CircularProgressbar-inverted"
 *     background
 *     percentage={50}
 *   />
 */
.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-background {
  fill: #3e98c7;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-text {
  fill: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-path {
  stroke: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-trail {
  stroke: transparent;
}

.style_lds-circle__3XnE0 {
    display: inline-block;
    margin: 8px;
    border-radius: 50%;
    -webkit-animation: style_lds-circle__3XnE0 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
            animation: style_lds-circle__3XnE0 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
@-webkit-keyframes style_lds-circle__3XnE0 {
    0%, 100% {
      -webkit-animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
              animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    }
    0% {
      -webkit-transform: rotateY(0deg);
              transform: rotateY(0deg);
    }
    50% {
      -webkit-transform: rotateY(1800deg);
              transform: rotateY(1800deg);
      -webkit-animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
              animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
    100% {
      -webkit-transform: rotateY(3600deg);
              transform: rotateY(3600deg);
    }
}
@keyframes style_lds-circle__3XnE0 {
    0%, 100% {
      -webkit-animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
              animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    }
    0% {
      -webkit-transform: rotateY(0deg);
              transform: rotateY(0deg);
    }
    50% {
      -webkit-transform: rotateY(1800deg);
              transform: rotateY(1800deg);
      -webkit-animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
              animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
    100% {
      -webkit-transform: rotateY(3600deg);
              transform: rotateY(3600deg);
    }
}
.style_lds-default__CECAn {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.style_lds-default__CECAn div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: #fff;
  border-radius: 50%;
  -webkit-animation: style_lds-default__CECAn 1.2s linear infinite;
          animation: style_lds-default__CECAn 1.2s linear infinite;
}
.style_lds-default__CECAn div:nth-child(1) {
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  top: 46.25%;
  left: 82.5%;
}
.style_lds-default__CECAn div:nth-child(2) {
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
  top: 27.5%;
  left: 77.5%;
}
.style_lds-default__CECAn div:nth-child(3) {
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
  top: 13.75%;
  left: 65%;
}
.style_lds-default__CECAn div:nth-child(4) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
  top: 8.75%;
  left: 46.25%;
}
.style_lds-default__CECAn div:nth-child(5) {
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
  top: 13.75%;
  left: 27.5%;
}
.style_lds-default__CECAn div:nth-child(6) {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
  top: 27.5%;
  left: 13.75%;
}
.style_lds-default__CECAn div:nth-child(7) {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
  top: 46.25%;
  left: 8.75%;
}
.style_lds-default__CECAn div:nth-child(8) {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
  top: 65%;
  left: 13.75%;
}
.style_lds-default__CECAn div:nth-child(9) {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
  top: 77.5%;
  left: 27.5%;
}
.style_lds-default__CECAn div:nth-child(10) {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
  top: 82.5%;
  left: 46.25%;
}
.style_lds-default__CECAn div:nth-child(11) {
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
  top: 77.5%;
  left: 65%;
}
.style_lds-default__CECAn div:nth-child(12) {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
  top: 65%;
  left: 77.5%;
}
@-webkit-keyframes style_lds-default__CECAn {
  0%,
  20%,
  80%,
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
}
@keyframes style_lds-default__CECAn {
  0%,
  20%,
  80%,
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
}

.style_lds-dual-ring__2n_iK {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.style_lds-dual-ring-after__w_qdo {
  content: ' ';
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #000;
  border-color: #000 transparent #000 transparent;
  -webkit-animation: style_lds-dual-ring__2n_iK 1.2s linear infinite;
          animation: style_lds-dual-ring__2n_iK 1.2s linear infinite;
}
@-webkit-keyframes style_lds-dual-ring__2n_iK {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes style_lds-dual-ring__2n_iK {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.style_lds-ellipsis__1j9-5 {
    display: inline-block;
    position: relative;
  }
  .style_lds-ellipsis__1j9-5 div {
    position: absolute;
    top: 41.25%;
    width: 16.25%;
    height: 16.25%;
    border-radius: 50%;
    background: #fff;
    -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
            animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .style_lds-ellipsis__1j9-5 div:nth-child(1) {
    left: 10%;
    -webkit-animation: style_lds-ellipsis1__16qCJ 0.6s infinite;
            animation: style_lds-ellipsis1__16qCJ 0.6s infinite;
  }
  .style_lds-ellipsis__1j9-5 div:nth-child(2) {
    left: 10%;
    -webkit-animation: style_lds-ellipsis2__3JWKc 0.6s infinite;
            animation: style_lds-ellipsis2__3JWKc 0.6s infinite;
  }
  .style_lds-ellipsis__1j9-5 div:nth-child(3) {
    left: 40%;
    -webkit-animation: style_lds-ellipsis2__3JWKc 0.6s infinite;
            animation: style_lds-ellipsis2__3JWKc 0.6s infinite;
  }
  .style_lds-ellipsis__1j9-5 div:nth-child(4) {
    left: 70%;
    -webkit-animation: style_lds-ellipsis3__3uvx4 0.6s infinite;
            animation: style_lds-ellipsis3__3uvx4 0.6s infinite;
  }
  @-webkit-keyframes style_lds-ellipsis1__16qCJ {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes style_lds-ellipsis1__16qCJ {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @-webkit-keyframes style_lds-ellipsis3__3uvx4 {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
  }
  @keyframes style_lds-ellipsis3__3uvx4 {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
  }
  @-webkit-keyframes style_lds-ellipsis2__3JWKc {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
    100% {
      -webkit-transform: translate(184.61%, 0);
              transform: translate(184.61%, 0);
    }
  }
  @keyframes style_lds-ellipsis2__3JWKc {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
    100% {
      -webkit-transform: translate(184.61%, 0);
              transform: translate(184.61%, 0);
    }
  }
.style_lds-facebook__1KZ29 {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .style_lds-facebook__1KZ29 div {
    display: inline-block;
    position: absolute;
    left: 10%;
    width: 20%;
    background: #fff;
    -webkit-animation: style_lds-facebook__1KZ29 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
            animation: style_lds-facebook__1KZ29 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }
  .style_lds-facebook__1KZ29 div:nth-child(1) {
    left: 10%;
    -webkit-animation-delay: -0.24s;
            animation-delay: -0.24s;
  }
  .style_lds-facebook__1KZ29 div:nth-child(2) {
    left: 40%;
    -webkit-animation-delay: -0.12s;
            animation-delay: -0.12s;
  }
  .style_lds-facebook__1KZ29 div:nth-child(3) {
    left: 70%;
    -webkit-animation-delay: 0;
            animation-delay: 0;
  }
  @-webkit-keyframes style_lds-facebook__1KZ29 {
    0% {
      top: 10%;
      height: 80%;
    }
    50%, 100% {
      top: 30%;
      height: 40%;
    }
  }
  @keyframes style_lds-facebook__1KZ29 {
    0% {
      top: 10%;
      height: 80%;
    }
    50%, 100% {
      top: 30%;
      height: 40%;
    }
  }

.style_lds-grid__2kyM2 {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.style_lds-grid__2kyM2 div {
  position: absolute;
  width: 20%;
  height: 20%;
  border-radius: 50%;
  background: #fff;
  -webkit-animation: style_lds-grid__2kyM2 1.2s linear infinite;
          animation: style_lds-grid__2kyM2 1.2s linear infinite;
}
.style_lds-grid__2kyM2 div:nth-child(1) {
  top: 10%;
  left: 10%;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}
.style_lds-grid__2kyM2 div:nth-child(2) {
  top: 10%;
  left: 40%;
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}
.style_lds-grid__2kyM2 div:nth-child(3) {
  top: 10%;
  left: 70%;
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}
.style_lds-grid__2kyM2 div:nth-child(4) {
  top: 40%;
  left: 10%;
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}
.style_lds-grid__2kyM2 div:nth-child(5) {
  top: 40%;
  left: 40%;
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}
.style_lds-grid__2kyM2 div:nth-child(6) {
  top: 40%;
  left: 70%;
  -webkit-animation-delay: -1.2s;
          animation-delay: -1.2s;
}
.style_lds-grid__2kyM2 div:nth-child(7) {
  top: 70%;
  left: 10%;
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}
.style_lds-grid__2kyM2 div:nth-child(8) {
  top: 70%;
  left: 40%;
  -webkit-animation-delay: -1.2s;
          animation-delay: -1.2s;
}
.style_lds-grid__2kyM2 div:nth-child(9) {
  top: 70%;
  left: 70%;
  -webkit-animation-delay: -1.6s;
          animation-delay: -1.6s;
}
@-webkit-keyframes style_lds-grid__2kyM2 {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
@keyframes style_lds-grid__2kyM2 {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.style_lds-heart__-QYLT {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transform-origin: 40px 40px;
            transform-origin: 40px 40px;
  }
  .style_lds-heart__-QYLT > div {
    top: 32px;
    left: 32px;
    position: absolute;
    width: 32px;
    height: 32px;
    background: #fff;
    -webkit-animation: style_lds-heart__-QYLT 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
            animation: style_lds-heart__-QYLT 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  .style_lds-heart__-QYLT .style_div-after__2xG0h,
  .style_lds-heart__-QYLT .style_div-before__pbzbH {
    content: " ";
    position: absolute;
    display: block;
    width: 32px;
    height: 32px;
    background: #fff;
  }
  .style_lds-heart__-QYLT .style_div-before__pbzbH {
    left: -24px;
    border-radius: 50% 0 0 50%;
  }
  .style_lds-heart__-QYLT .style_div-after__2xG0h {
    top: -24px;
    border-radius: 50% 50% 0 0;
  }
  @-webkit-keyframes style_lds-heart__-QYLT {
    0% {
      -webkit-transform: scale(0.95);
              transform: scale(0.95);
    }
    5% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }
    39% {
      -webkit-transform: scale(0.85);
              transform: scale(0.85);
    }
    45% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    60% {
      -webkit-transform: scale(0.95);
              transform: scale(0.95);
    }
    100% {
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
    }
  }
  @keyframes style_lds-heart__-QYLT {
    0% {
      -webkit-transform: scale(0.95);
              transform: scale(0.95);
    }
    5% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }
    39% {
      -webkit-transform: scale(0.85);
              transform: scale(0.85);
    }
    45% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    60% {
      -webkit-transform: scale(0.95);
              transform: scale(0.95);
    }
    100% {
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
    }
  }
.style_lds-hourglass__1FsbH {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.style_lds-hourglass-after__2e2rJ {
  content: ' ';
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 8px;
  box-sizing: border-box;
  border: 32px solid #fff;
  border-color: #fff transparent #fff transparent;
  -webkit-animation: style_lds-hourglass__1FsbH 1.2s infinite;
          animation: style_lds-hourglass__1FsbH 1.2s infinite;
}
@-webkit-keyframes style_lds-hourglass__1FsbH {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    -webkit-transform: rotate(900deg);
            transform: rotate(900deg);
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    -webkit-transform: rotate(1800deg);
            transform: rotate(1800deg);
  }
}
@keyframes style_lds-hourglass__1FsbH {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    -webkit-transform: rotate(900deg);
            transform: rotate(900deg);
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    -webkit-transform: rotate(1800deg);
            transform: rotate(1800deg);
  }
}

.style_lds-orbitals__2AuzO {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.style_lds-orbitals__2AuzO * {
  --center: translate(-50%, -50%);
}
.style_lds-orbitals__2AuzO .style_center__1Uhy1 {
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  -webkit-transform: var(--center);
          transform: var(--center);
}
.style_lds-orbitals__2AuzO .style_outer-spin__28F7e,
.style_lds-orbitals__2AuzO .style_inner-spin__XqK1B {
  position: absolute;
  top: 50%;
  left: 50%;
}
.style_lds-orbitals__2AuzO .style_inner-arc__1DFW7 {
  position: absolute;
  width: 31px;
  height: 31px;
  border-radius: 50%;
  border: 3px solid;
}
.style_lds-orbitals__2AuzO .style_inner-arc_start-a__1BX3o {
  border-color: transparent transparent transparent;
  -webkit-transform: var(--center) rotate(45deg);
          transform: var(--center) rotate(45deg);
}
.style_lds-orbitals__2AuzO .style_inner-arc_end-a__38sqo {
  border-color: transparent transparent transparent;
  -webkit-transform: var(--center) rotate(25deg);
          transform: var(--center) rotate(25deg);
}
.style_lds-orbitals__2AuzO .style_inner-moon-a__1jeC1 {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  -webkit-transform: var(--center) translate(17px, 0);
          transform: var(--center) translate(17px, 0);
}
.style_lds-orbitals__2AuzO .style_inner-moon-b__6kS40 {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  -webkit-transform: var(--center) translate(-17px, 0);
          transform: var(--center) translate(-17px, 0);
}
.style_lds-orbitals__2AuzO .style_inner-arc_start-b__2qDGi {
  border-color: transparent transparent transparent;
  -webkit-transform: var(--center) rotate(65deg) scale(-1, -1);
          transform: var(--center) rotate(65deg) scale(-1, -1);
}
.style_lds-orbitals__2AuzO .style_inner-arc_end-b__3-IO2 {
  border-color: transparent transparent transparent;
  -webkit-transform: var(--center) rotate(45deg) scale(-1, -1);
          transform: var(--center) rotate(45deg) scale(-1, -1);
}
.style_lds-orbitals__2AuzO .style_outer-arc__2g6LA {
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 3px solid;
}
.style_lds-orbitals__2AuzO .style_outer-arc_start-a__Kikxd {
  border-color: transparent transparent transparent;
  -webkit-transform: var(--center) rotate(65deg);
          transform: var(--center) rotate(65deg);
}
.style_lds-orbitals__2AuzO .style_outer-arc_end-a__2z8WV {
  border-color: transparent transparent transparent;
  -webkit-transform: var(--center) rotate(45deg);
          transform: var(--center) rotate(45deg);
}
.style_lds-orbitals__2AuzO .style_outer-moon-a__HmNIk {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  -webkit-transform: var(--center) translate(32px, 0);
          transform: var(--center) translate(32px, 0);
}
.style_lds-orbitals__2AuzO .style_outer-moon-b__2gsiH {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  -webkit-transform: var(--center) translate(-32px, 0);
          transform: var(--center) translate(-32px, 0);
}
.style_lds-orbitals__2AuzO .style_outer-arc_start-b__36K-L {
  border-color: transparent transparent transparent;
  -webkit-transform: var(--center) rotate(65deg) scale(-1, -1);
          transform: var(--center) rotate(65deg) scale(-1, -1);
}
.style_lds-orbitals__2AuzO .style_outer-arc_end-b__1vK2J {
  border-color: transparent transparent transparent;
  -webkit-transform: var(--center) rotate(45deg) scale(-1, -1);
          transform: var(--center) rotate(45deg) scale(-1, -1);
}
.style_lds-orbitals__2AuzO .style_outer-spin__28F7e {
  -webkit-animation: style_spin__2BB9p 4s linear infinite;
          animation: style_spin__2BB9p 4s linear infinite;
}
.style_lds-orbitals__2AuzO .style_inner-spin__XqK1B {
  -webkit-animation: style_spin__2BB9p 3s linear infinite;
          animation: style_spin__2BB9p 3s linear infinite;
}
@-webkit-keyframes style_spin__2BB9p {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes style_spin__2BB9p {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.style_lds-ring__2XoeF {
  display: inline-block;
  position: relative;
}
.style_lds-ring__2XoeF div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  border: 8px solid #fff;
  border-radius: 50%;
  -webkit-animation: style_lds-ring__2XoeF 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: style_lds-ring__2XoeF 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.style_lds-ring__2XoeF div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}
.style_lds-ring__2XoeF div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.style_lds-ring__2XoeF div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}
@-webkit-keyframes style_lds-ring__2XoeF {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes style_lds-ring__2XoeF {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.style_lds-ripple__2S-5g {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.style_lds-ripple__2S-5g div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  -webkit-animation: style_lds-ripple__2S-5g 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
          animation: style_lds-ripple__2S-5g 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.style_lds-ripple__2S-5g div:nth-child(2) {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}

@-webkit-keyframes style_lds-ripple__2S-5g {
  0% {
    top: 45%;
    left: 45%;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0px;
    left: 0px;
    width: 90%;
    height: 90%;
    opacity: 0;
  }
}

@keyframes style_lds-ripple__2S-5g {
  0% {
    top: 45%;
    left: 45%;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0px;
    left: 0px;
    width: 90%;
    height: 90%;
    opacity: 0;
  }
}

.style_lds-roller__aZ_3P {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.style_lds-roller__aZ_3P > div {
  -webkit-animation: style_lds-roller__aZ_3P 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: style_lds-roller__aZ_3P 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  -webkit-transform-origin: 40px 40px;
          transform-origin: 40px 40px;
}
.style_lds-roller__aZ_3P div .style_div-after__2_oFv {
  content: ' ';
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}
.style_lds-roller__aZ_3P div:nth-child(1) {
  -webkit-animation-delay: -0.036s;
          animation-delay: -0.036s;
}
.style_lds-roller__aZ_3P div:nth-child(1) .style_div-after__2_oFv {
  top: 63px;
  left: 63px;
}
.style_lds-roller__aZ_3P div:nth-child(2) {
  -webkit-animation-delay: -0.072s;
          animation-delay: -0.072s;
}
.style_lds-roller__aZ_3P div:nth-child(2) .style_div-after__2_oFv {
  top: 68px;
  left: 56px;
}
.style_lds-roller__aZ_3P div:nth-child(3) {
  -webkit-animation-delay: -0.108s;
          animation-delay: -0.108s;
}
.style_lds-roller__aZ_3P div:nth-child(3) .style_div-after__2_oFv {
  top: 71px;
  left: 48px;
}
.style_lds-roller__aZ_3P div:nth-child(4) {
  -webkit-animation-delay: -0.144s;
          animation-delay: -0.144s;
}
.style_lds-roller__aZ_3P div:nth-child(4) .style_div-after__2_oFv {
  top: 72px;
  left: 40px;
}
.style_lds-roller__aZ_3P div:nth-child(5) {
  -webkit-animation-delay: -0.18s;
          animation-delay: -0.18s;
}
.style_lds-roller__aZ_3P div:nth-child(5) .style_div-after__2_oFv {
  top: 71px;
  left: 32px;
}
.style_lds-roller__aZ_3P div:nth-child(6) {
  -webkit-animation-delay: -0.216s;
          animation-delay: -0.216s;
}
.style_lds-roller__aZ_3P div:nth-child(6) .style_div-after__2_oFv {
  top: 68px;
  left: 24px;
}
.style_lds-roller__aZ_3P div:nth-child(7) {
  -webkit-animation-delay: -0.252s;
          animation-delay: -0.252s;
}
.style_lds-roller__aZ_3P div:nth-child(7) .style_div-after__2_oFv {
  top: 63px;
  left: 17px;
}
.style_lds-roller__aZ_3P div:nth-child(8) {
  -webkit-animation-delay: -0.288s;
          animation-delay: -0.288s;
}
.style_lds-roller__aZ_3P div:nth-child(8) .style_div-after__2_oFv {
  top: 56px;
  left: 12px;
}
@-webkit-keyframes style_lds-roller__aZ_3P {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes style_lds-roller__aZ_3P {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.style_lds-spinner__VRDxV {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.style_lds-spinner__VRDxV div {
  -webkit-transform-origin: 40px 40px;
          transform-origin: 40px 40px;
  -webkit-animation: style_lds-spinner__VRDxV 1.2s linear infinite;
          animation: style_lds-spinner__VRDxV 1.2s linear infinite;
}
.style_lds-spinner__VRDxV div .style_div-after__xaYuT {
  content: ' ';
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #fff;
}
.style_lds-spinner__VRDxV div:nth-child(1) {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
}
.style_lds-spinner__VRDxV div:nth-child(2) {
  -webkit-transform: rotate(30deg);
          transform: rotate(30deg);
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}
.style_lds-spinner__VRDxV div:nth-child(3) {
  -webkit-transform: rotate(60deg);
          transform: rotate(60deg);
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
}
.style_lds-spinner__VRDxV div:nth-child(4) {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}
.style_lds-spinner__VRDxV div:nth-child(5) {
  -webkit-transform: rotate(120deg);
          transform: rotate(120deg);
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
}
.style_lds-spinner__VRDxV div:nth-child(6) {
  -webkit-transform: rotate(150deg);
          transform: rotate(150deg);
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
}
.style_lds-spinner__VRDxV div:nth-child(7) {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}
.style_lds-spinner__VRDxV div:nth-child(8) {
  -webkit-transform: rotate(210deg);
          transform: rotate(210deg);
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}
.style_lds-spinner__VRDxV div:nth-child(9) {
  -webkit-transform: rotate(240deg);
          transform: rotate(240deg);
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.style_lds-spinner__VRDxV div:nth-child(10) {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
}
.style_lds-spinner__VRDxV div:nth-child(11) {
  -webkit-transform: rotate(300deg);
          transform: rotate(300deg);
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
}
.style_lds-spinner__VRDxV div:nth-child(12) {
  -webkit-transform: rotate(330deg);
          transform: rotate(330deg);
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}
@-webkit-keyframes style_lds-spinner__VRDxV {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes style_lds-spinner__VRDxV {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.style_lds-ouroboro__1wEau {
  position: relative;
  display: inline-block;
  height: 64px;
  width: 64px;
  margin: 0.5em;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) inset, 0 0 25px rgba(0, 0, 255, 0.075);
}

.style_lds-ouroboro__1wEau:after {
  content: '';
  position: relative;
  top: 15%;
  left: 15%;
  display: block;
  height: 70%;
  width: 70%;
  background: none repeat scroll 0 0 #f2f2f2;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.style_lds-ouroboro__1wEau > span {
  position: absolute;
  height: 100%;
  width: 50%;
  overflow: hidden;
}
.style_lds-ouroboro__1wEau > .style_left__2zdm1 {
  left: 0;
}
.style_lds-ouroboro__1wEau > .style_right__3Sxlo {
  left: 50%;
}

.style_lds-ouroboro__1wEau > .style_left__2zdm1 > .style_anim__1ANE7,
.style_lds-ouroboro__1wEau > .style_right__3Sxlo > .style_anim__1ANE7 {
  position: absolute;
  left: 100%;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 999px;
  background: none repeat scroll 0 0 #508ec3;
  -webkit-animation: style_lds-ouroboro-rotate__2Mcfz 3s infinite;
          animation: style_lds-ouroboro-rotate__2Mcfz 3s infinite;
  opacity: 0.8;
  -webkit-transform-origin: 0 50% 0;
          transform-origin: 0 50% 0;
}
.style_lds-ouroboro__1wEau > .style_left__2zdm1 > .style_anim__1ANE7 {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.style_lds-ouroboro__1wEau > .style_right__3Sxlo > .style_anim__1ANE7 {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  left: -100%;
  -webkit-transform-origin: 100% 50% 0;
          transform-origin: 100% 50% 0;
}

@-webkit-keyframes style_lds-ouroboro-rotate__2Mcfz {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  75% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes style_lds-ouroboro-rotate__2Mcfz {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  75% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

